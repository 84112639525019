import React, { useCallback } from "react"
import { withRouter } from "react-router-dom"
import Clock from "react-live-clock"
import styled from "styled-components"

import Color from "../../assets/color"
import { ControllerButton } from "../atoms"
import { SliderController } from "../molecules"

import arrowPrev from "../../assets/icons/controller/arrow-prev.svg"
import arrowNext from "../../assets/icons/controller/arrow-next.svg"
import dotButton from "../../assets/icons/controller/dot.svg"
import volumeIncrease from "../../assets/icons/controller/volume-increase.svg"
import volumeDecrease from "../../assets/icons/controller/volume-decrease.svg"

import api from "../../utils/api"

const Footer = (props) => {
  const _increaseVolume = () => {
    // console.log("Volume INCREASE")
    controlVolume("increase")
  }

  const _decreaseVolume = () => {
    // console.log("Volume DECREASE")
    controlVolume("decrease")
  }

  const controlVolume = (value) => {
    let controlValues = ["increase", "decrease"]
    let finalValue

    if (controlValues.includes(value)) {
      finalValue = value
    } else {
      finalValue = "increase"
    }

    const url = `pedle-console-volume/volume.php?volume=${finalValue}`

    api
      .get(url, {
        baseURL: "http://127.0.0.1/",
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const VolumeButtons = useCallback(() => {
    return (
      <VolumeWrapper className="volume-wrapper">
        {/* <FooterText className="volume-text">volume</FooterText>
        <ControllerButton className="volume-icon" onClick={_decreaseVolume}>
          <img src={volumeDecrease} alt="" />
        </ControllerButton>
        <ControllerButton className="volume-icon" onClick={_increaseVolume}>
          <img src={volumeIncrease} alt="" />
        </ControllerButton> */}
      </VolumeWrapper>
    )
  }, [])

  return (
    <FooterDiv className="footer" {...props}>
      <FooterWrapper>
        <LeftWrapper>
          {props.hideClock !== true && props.isSleepMode !== true && (
            <React.Fragment>
              <StyledClock format={"HH:mm"} ticking={true} />
              {!!props.showVolumeControls && <VolumeButtons />}
            </React.Fragment>
          )}
        </LeftWrapper>
        <RightWrapper>
          {/* Default - if page nav enabled */}
          {!props.isModalScreen && !props.isCallScreen && (
            <React.Fragment>
              {!!props.isShowPageNav && (
                <SliderController
                  ref={props.sliderProps.sliderRef}
                  {...props.sliderProps}
                />
              )}
            </React.Fragment>
          )}

          {/* Call */}
          {!!props.isCallScreen &&
            !props.isModalScreen &&
            !props.isShowPageNav && (
              <React.Fragment>
                <ControllerButton />
                <ControllerButton />
                {(props.callProps.showActionButton && (
                  <ControllerButton
                    onClick={props.callProps.onClickActionButton}
                  >
                    <img src={dotButton} alt="" />
                  </ControllerButton>
                )) || <ControllerButton />}
              </React.Fragment>
            )}

          {/* Modal */}
          {!!props.isModalScreen && (
            <React.Fragment>
              <ControllerButton />
              <ControllerButton />
              {(props.modalProps.showActionButton && (
                <ControllerButton
                  onClick={props.modalProps.onClickActionButton}
                >
                  <img src={dotButton} alt="" />
                </ControllerButton>
              )) || <ControllerButton />}
            </React.Fragment>
          )}

          {/* Games */}
          {!!props.isGamesScreen && (
            <React.Fragment>
              {/* Volume button here */}
              {props.gamesProps.onClickPrevButton && (
                <ControllerButton onClick={props.gamesProps.onClickPrevButton}>
                  <img src={arrowPrev} alt="" />
                </ControllerButton>
              )}
              {props.gamesProps.onClickNextButton && (
                <ControllerButton onClick={props.gamesProps.onClickNextButton}>
                  <img src={arrowNext} alt="" />
                </ControllerButton>
              )}

              {/* Placeholder button */}
              <ControllerButton />

              {(props.gamesProps.onClickActionButton && (
                <ControllerButton
                  onClick={props.gamesProps.onClickActionButton}
                >
                  <img src={dotButton} alt="" />
                </ControllerButton>
              )) || <ControllerButton />}
            </React.Fragment>
          )}
        </RightWrapper>
      </FooterWrapper>
    </FooterDiv>
  )
}

const FooterDiv = styled.div`
  min-height: 15vh;
  display: flex;
  background-color: ${(props) =>
    props.isSleepMode === true
      ? "transparent"
      : props.isAltBg
      ? Color.hippieBlue
      : Color.blue};
  color: #fff;
  font-size: 2.65vw;
  line-height: 1;
  font-weight: bold;
`

const FooterWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
`

const LeftWrapper = styled.div`
  padding-left: 2.5rem;
  display: flex;
  align-items: center;
`

const StyledClock = styled(Clock)``

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FooterText = styled.div`
  align-self: center;
`

const VolumeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8rem;

  .volume-text {
    font-weight: 400;
    font-size: 2.35vw;
    margin-right: 0.75rem;
  }

  .volume-icon {
    width: 6.25vw;
    margin: 0 0.5rem;

    img {
      width: 100%;
    }
  }
`

export default withRouter(Footer)

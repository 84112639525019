import React, { forwardRef } from "react"
import styled from "styled-components"

import { MainContainer, Header, Footer, CallPartnerThumbnail } from "./../atoms"

import { dotKey, defaultCategoryMenu } from "../../utils/constant"
import { _triggerKeyPress } from "../../utils/helper"

const VideoCallLayout = forwardRef((props, ref) => {
  const { isCaller, partner, isCallAccepted, isSpeakerOn } = props

  return (
    <MainContainer>
      <Header
        isCallScreen
        callProps={{
          onClickHome: () => _triggerKeyPress("keyup", defaultCategoryMenu),
        }}
        transparentHeader={isCallAccepted}
        disableLeftWrapperPadding={isCallAccepted}
      >
        {isCallAccepted ? (
          <OnCallHeader>
            <CallPartnerThumbnail src={partner?.avatar} />
            <div className="partner-name">{partner?.name}</div>
          </OnCallHeader>
        ) : isCaller ? (
          `Je belt ${partner?.name}`
        ) : (
          `${partner?.name} belt jou`
        )}
      </Header>
      <VideoContent>
        {isCallAccepted === true ? (
          <VideoOuterWrapper>
            <VideoWrapper isSpeakerOn={isSpeakerOn}>
              {/* <Video ref={partnerVideo} playsInline autoPlay /> */}
              {/* <Video ref={userVideo} playsInline autoPlay /> */}
              {props.children}
            </VideoWrapper>
            <CallPartnerThumbnail
              src={partner.avatar}
              className={"size-large call-ongoing"}
              isSpeakerOn={isSpeakerOn}
            />
          </VideoOuterWrapper>
        ) : (
          <CallPartnerThumbnail
            className={`size-large ${isCaller === true ? "call-outgoing" : ""}`}
            src={partner?.avatar}
          />
        )}
      </VideoContent>

      <Footer
        showVolumeControls
        isCallScreen
        callProps={{
          showActionButton:
            isCaller && isCallAccepted ? true : !isCaller ? true : false,
          onClickActionButton: () => _triggerKeyPress("keyup", dotKey),
        }}
      />
    </MainContainer>
  )
})

const VideoContent = styled.div`
  height: 65vh;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoOuterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const OnCallHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;

  img {
    margin-right: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .partner-name {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export default VideoCallLayout
